import logo from './logo.svg';
import './App.css';
import Main from './Main';
import Header from './components/Header';

function App() {

  return (
      <div className="App">
        <Header/>
        <Main />
      </div>
  );
}

export default App;
